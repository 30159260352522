// 引入封装的axios请求文件
import req from '../utils/request.js'

// shipment list
export function shipmentList (params) {
  return req.post('shipmentList', params)
}
// 获取的manifest name列表
export function getManifestListByName (params) {
  return req.post('getManifestListByName', params)
}
