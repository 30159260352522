<template>
  <div class="content-box">
    <div class="welcom">👏 Welcome back, {{ Username.first_name }}!</div>
    <div class="top-show">
      <a-row :gutter="24">
        <a-col :span="18">
          <a-card title="Wallet" style="margin-top:24px;">
            <template #extra>
              <div class="more-msg">
                <a-button type="text" @click="$router.push('/usercenter/wallet')">
                  More
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style="padding-left:4px;">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.35352 10.2317L3.64641 9.52458L7.18194 5.98905L3.64641 2.45352L4.35351 1.74641L8.24257 5.63546C8.24259 5.63548 8.2426 5.6355 7.88905 5.98905L8.24257 5.63546L8.59616 5.98905L4.35352 10.2317Z"
                      fill="#4E5969" />
                  </svg>
                </a-button>
              </div>
            </template>
            <a-row :gutter="24">
              <a-col :span="8">
                <a-statistic title="Balance" show-group-separator>
                  <template #extra>
                    <a-space>
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#FDDDC3" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15.3333 34.6666V13.3333L32.6666 13.3333V34.6666L15.3333 34.6666ZM15.25 10.6666C13.7435 10.6666 12.6666 11.9429 12.6666 13.3333V34.6666C12.6666 36.057 13.7435 37.3333 15.25 37.3333H32.75C34.2564 37.3333 35.3333 36.057 35.3333 34.6666V13.3333C35.3333 11.9429 34.2565 10.6666 32.75 10.6666H15.25ZM19.3333 18.6666L28.6666 18.6666V16L19.3333 16V18.6666ZM24 27.3333C22.5272 27.3333 21.3333 28.5272 21.3333 30C21.3333 31.4727 22.5272 32.6666 24 32.6666C25.4727 32.6666 26.6666 31.4727 26.6666 30C26.6666 28.5272 25.4727 27.3333 24 27.3333Z"
                          fill="#F99057" />
                      </svg>
                      <span class="price">${{ balance }}</span>
                      <a-button @click="$router.push('/usercenter/recharge')">Recharge</a-button>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="8">
                <a-statistic title="Expense" show-group-separator>
                  <template #extra>
                    <a-space>
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#E8FFFB" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M22.6667 15.3334V11.3334H25.3334V15.3334H22.6667ZM17.6096 12.3906L20.2762 15.0572L18.3906 16.9428L15.7239 14.2762L17.6096 12.3906ZM32.2762 14.2762L29.6096 16.9428L27.7239 15.0572L30.3906 12.3906L32.2762 14.2762ZM15.1292 19.9897C15.6071 19.1705 16.4841 18.6667 17.4326 18.6667L30.5676 18.6667C31.516 18.6667 32.3931 19.1705 32.871 19.9897L36.9702 27.0168C37.2081 27.4247 37.3334 27.8884 37.3334 28.3605V34C37.3334 35.4728 36.1395 36.6667 34.6667 36.6667H13.3334C11.8607 36.6667 10.6667 35.4728 10.6667 34L10.6667 28.3605C10.6667 27.8884 10.7921 27.4247 11.03 27.0168L15.1292 19.9897ZM30.5676 21.3334H17.4326L14.3215 26.6667H22.2944L22.5944 27.5668C22.5958 27.5704 22.5983 27.5769 22.6019 27.5857C22.6125 27.6118 22.632 27.6575 22.6614 27.7163C22.7216 27.8367 22.8142 27.9943 22.941 28.1465C23.1864 28.4409 23.5082 28.6667 24.0001 28.6667C24.492 28.6667 24.8137 28.4409 25.0591 28.1465C25.1859 27.9943 25.2786 27.8367 25.3388 27.7163C25.3681 27.6575 25.3877 27.6118 25.3983 27.5857C25.4009 27.5791 25.403 27.5739 25.4044 27.5701C25.4049 27.5689 25.4053 27.5677 25.4057 27.5668L25.7057 26.6667L33.6787 26.6667L30.5676 21.3334ZM34.6667 29.3334H27.4849C27.3814 29.4979 27.2565 29.6751 27.1077 29.8536C26.5198 30.5591 25.5082 31.3334 24.0001 31.3334C22.492 31.3334 21.4804 30.5591 20.8925 29.8536C20.7436 29.6751 20.6188 29.4979 20.5153 29.3334H13.3334L13.3334 34H34.6667V29.3334Z"
                          fill="#5EDFD6" />
                      </svg>
                      <span class="price">
                        ${{ expenseval }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="expense.val.percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980551 3.78403 0.0980096 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="expense.val.percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="8">
                <a-statistic title="Recharge" show-group-separator>
                  <template #extra>
                    <a-space>
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#E8F7FF" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M16.9308 16.9271C18.4221 15.4358 20.2636 14.5156 22.1898 14.1627L21.7093 11.5397C19.2678 11.9869 16.9313 13.1554 15.0452 15.0415C10.0985 19.9882 10.0985 28.0083 15.0452 32.9549L12.769 35.231H19.2069V28.7931L16.9308 31.0693C13.0256 27.164 13.0256 20.8324 16.9308 16.9271ZM35.2323 12.7677H28.7944V19.2056L31.0706 16.9295C34.9758 20.8347 34.9758 27.1664 31.0706 31.0716C29.5793 32.5629 27.7378 33.4832 25.8115 33.8361L26.2921 36.4591C28.7336 36.0118 31.0701 34.8433 32.9562 32.9573C37.9028 28.0106 37.9028 19.9905 32.9562 15.0439L35.2323 12.7677Z"
                          fill="#7BC0FC" />
                      </svg>
                      <span class="price">
                        ${{ rechargeval }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="recharge.val.percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980551 3.78403 0.0980096 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="recharge.val.percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
          <a-card title="Shipments Overview" style="margin-top:20px;">
            <template #extra>
              <a-radio-group type="button" @change="Overview" :modelValue="selsectOverview">
                <a-radio :value="1">D</a-radio>
                <a-radio :value="2">W</a-radio>
                <a-radio :value="3">1M</a-radio>
                <a-radio :value="4">3M</a-radio>
                <a-radio :value="5">1Y</a-radio>
                <a-radio :value="6">All</a-radio>
              </a-radio-group>
            </template>
            <a-row :gutter="24">
              <a-col :span="6">
                <a-statistic title="Total" show-group-separator>
                  <template #extra>
                    <a-space size="large">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#FFF3E8" />
                        <path
                          d="M24.047 18.636L26.394 22.5504C27.0321 23.6126 28.3041 24.0794 29.499 23.7753L34.9638 22.2164V29.3644C34.9638 30.3066 34.3214 31.1289 33.4049 31.3601L24.6638 33.5443C24.2269 33.6557 23.7729 33.6557 23.3361 33.5443L14.5949 31.3601C13.678 31.1289 13.036 30.3066 13.036 29.3644V22.2164L18.5008 23.7753C19.6957 24.0794 20.9677 23.6126 21.6059 22.5504L23.9528 18.636H24.047Z"
                          fill="#FDDDC3" />
                        <path
                          d="M13.5298 14.3775L23.9998 15.6472L34.471 14.3775C34.7576 14.3416 35.0357 14.4912 35.1683 14.7503L36.952 18.3149C37.3327 19.0802 36.9263 20.0079 36.1007 20.243L29.1156 22.2395C28.521 22.4062 27.8837 22.1583 27.5672 21.5897L23.9998 15.6472L20.4323 21.5897C20.1158 22.1583 19.4785 22.4062 18.8839 22.2395L11.8975 20.243C11.0736 20.0079 10.6661 19.0802 11.0493 18.3149L12.833 14.7503C12.9626 14.4912 13.2419 14.3416 13.5298 14.3775Z"
                          fill="#FAAC7B" />
                      </svg>
                      <span class="price">
                        {{ shipmentView.total }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.total_percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.total_percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980552 3.78403 0.0980095 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="6">
                <a-statistic title="Undelivered" show-group-separator>
                  <template #extra>
                    <a-space size="large">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#E8F7FF" />
                        <path
                          d="M20.7109 33.0453C20.7109 33.6995 20.451 34.327 19.9883 34.7896C19.5257 35.2522 18.8982 35.5121 18.244 35.5121C17.5897 35.5121 16.9623 35.2522 16.4996 34.7896C16.037 34.327 15.7771 33.6995 15.7771 33.0453C15.7771 32.391 16.037 31.7635 16.4996 31.3009C16.9623 30.8383 17.5897 30.5784 18.244 30.5784C18.8982 30.5784 19.5257 30.8383 19.9883 31.3009C20.451 31.7635 20.7109 32.391 20.7109 33.0453ZM32.223 33.0453C32.223 33.6995 31.9631 34.327 31.5004 34.7896C31.0378 35.2522 30.4104 35.5121 29.7561 35.5121C29.1018 35.5121 28.4744 35.2522 28.0118 34.7896C27.5491 34.327 27.2892 33.6995 27.2892 33.0453C27.2892 32.391 27.5491 31.7635 28.0118 31.3009C28.4744 30.8383 29.1018 30.5784 29.7561 30.5784C30.4104 30.5784 31.0378 30.8383 31.5004 31.3009C31.9631 31.7635 32.223 32.391 32.223 33.0453Z"
                          fill="#3491FA" />
                        <path
                          d="M12.4878 12.4879C12.0517 12.4879 11.6334 12.6612 11.325 12.9696C11.0165 13.278 10.8433 13.6963 10.8433 14.1325V30.5784C10.8433 31.0146 11.0165 31.4329 11.325 31.7413C11.6334 32.0497 12.0517 32.223 12.4878 32.223H14.2147C14.4034 31.2935 14.9076 30.4579 15.642 29.8577C16.3763 29.2576 17.2955 28.9297 18.2439 28.9297C19.1923 28.9297 20.1115 29.2576 20.8459 29.8577C21.5802 30.4579 22.0844 31.2935 22.2731 32.223H24C24.4361 32.223 24.8544 32.0497 25.1629 31.7413C25.4713 31.4329 25.6446 31.0146 25.6446 30.5784V14.1325C25.6446 13.6963 25.4713 13.278 25.1629 12.9696C24.8544 12.6612 24.4361 12.4879 24 12.4879H12.4878ZM30.5783 17.4217C30.1421 17.4217 29.7238 17.5949 29.4154 17.9034C29.107 18.2118 28.9337 18.6301 28.9337 19.0663V29.016C29.4629 28.9079 30.0081 28.9051 30.5383 29.0078C31.0685 29.1105 31.5733 29.3166 32.0239 29.6144C32.4744 29.9122 32.8618 30.2959 33.1641 30.7435C33.4663 31.1911 33.6774 31.6938 33.7853 32.223H35.5121C35.9483 32.223 36.3666 32.0497 36.675 31.7413C36.9834 31.4329 37.1567 31.0146 37.1567 30.5784V22.3554C37.1566 21.9193 36.9833 21.5011 36.6748 21.1927L33.3856 17.9035C33.0773 17.5951 32.659 17.4218 32.2229 17.4217H30.5783Z"
                          fill="#9FD4FD" />
                      </svg>
                      <span class="price">
                        {{ shipmentView.undelivered }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.undelivered_percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.undelivered_percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980552 3.78403 0.0980095 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="6">
                <a-statistic title="Delivered" show-group-separator>
                  <template #extra>
                    <a-space size="large">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#E8FFF0" />
                        <path
                          d="M22.8289 17.4631L25.1759 21.3776C25.814 22.4397 27.086 22.9066 28.2809 22.6025L33.7457 21.0435V28.1915C33.7457 29.1337 33.1033 29.956 32.1868 30.1873L23.4456 32.3715C23.0088 32.4829 22.5548 32.4829 22.118 32.3715L13.3768 30.1873C12.4599 29.956 11.8179 29.1337 11.8179 28.1915V21.0435L17.2827 22.6025C18.4776 22.9066 19.7496 22.4397 20.3877 21.3776L22.7347 17.4631H22.8289Z"
                          fill="#85E3AB" />
                        <path
                          d="M12.3116 13.2046L22.7816 14.4743L33.2529 13.2046C33.5395 13.1687 33.8175 13.3183 33.9501 13.5774L35.7338 17.1421C36.1145 17.9073 35.7082 18.835 34.8826 19.0702L27.8975 21.0667C27.3029 21.2334 26.6656 20.9854 26.349 20.4168L22.7816 14.4743L19.2142 20.4168C18.8977 20.9854 18.2603 21.2334 17.6658 21.0667L10.6793 19.0702C9.8555 18.835 9.44799 17.9073 9.83112 17.1421L11.6148 13.5774C11.7444 13.3183 12.0238 13.1687 12.3116 13.2046Z"
                          fill="#13B966" />
                        <circle cx="31.614" cy="33.2999" r="6.70017" fill="#B2F1D3" />
                        <path d="M28.5684 33.2998L30.8371 35.5686L34.4918 31.914" stroke="#13B966" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span class="price">
                        {{ shipmentView.delivered }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.delivered_percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.delivered_percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980552 3.78403 0.0980095 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="6">
                <a-statistic title="Exceptional" show-group-separator>
                  <template #extra>
                    <a-space size="large">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.000244141" width="48" height="48" rx="6" fill="#FFF3E8" />
                        <path
                          d="M23.438 17.8995L25.785 21.814C26.4231 22.8761 27.6951 23.343 28.89 23.0389L34.3548 21.4799V28.6279C34.3548 29.5701 33.7124 30.3924 32.7959 30.6237L24.0547 32.8079C23.6179 32.9193 23.1639 32.9193 22.7271 32.8079L13.9859 30.6237C13.069 30.3924 12.427 29.5701 12.427 28.6279V21.4799L17.8918 23.0389C19.0867 23.343 20.3587 22.8761 20.9968 21.814L23.3438 17.8995H23.438Z"
                          fill="#FFC4CB" />
                        <path
                          d="M12.9208 13.641L23.3907 14.9108L33.862 13.641C34.1486 13.6051 34.4266 13.7547 34.5592 14.0138L36.3429 17.5785C36.7236 18.3437 36.3173 19.2714 35.4917 19.5066L28.5066 21.5031C27.912 21.6698 27.2747 21.4218 26.9582 20.8532L23.3907 14.9108L19.8233 20.8532C19.5068 21.4218 18.8695 21.6698 18.2749 21.5031L11.2885 19.5066C10.4646 19.2714 10.0571 18.3437 10.4403 17.5785L12.224 14.0138C12.3536 13.7547 12.6329 13.6051 12.9208 13.641Z"
                          fill="#FC5E5E" />
                        <circle cx="31.0049" cy="31.2998" r="6.70017" fill="#FCDADA" />
                        <circle r="1.10285" transform="matrix(-1 0 0 1 30.9848 34.8507)" fill="#FC5E5E" />
                        <rect x="-0.551426" y="0.551426" width="1.10285" height="4.77903" rx="0.551426"
                          transform="matrix(-1 0 0 1 30.9848 27.2883)" stroke="#FC5E5E" stroke-width="1.10285" />
                      </svg>
                      <span class="price">
                        {{ shipmentView.exceptional }}
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.exceptional_percent < 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 0.166626H5.16667V7.16663H6.91044C7.40749 7.16663 7.67901 7.74635 7.36081 8.12819L4.45037 11.6207C4.21601 11.9019 3.78403 11.902 3.54963 11.6207L0.639192 8.12819C0.32103 7.74639 0.592458 7.16663 1.08956 7.16663H2.83333V0.166626Z"
                            fill="#F53F3F" />
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                          v-show="shipmentView.exceptional_percent > 0">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.83333 11.8334L5.16667 11.8334L5.16666 4.83337L6.91044 4.83337C7.40749 4.83337 7.67901 4.25365 7.36081 3.87181L4.45037 0.379288C4.21601 0.0980552 3.78403 0.0980095 3.54963 0.379288L0.639191 3.87182C0.321028 4.25361 0.592457 4.83337 1.08956 4.83337L2.83333 4.83337L2.83333 11.8334Z"
                            fill="#00B42A" />
                        </svg>
                      </span>
                    </a-space>
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card title="Quick Access">
            <div class="quick-box">
              <a-row :gutter="[24, 24]">
                <a-col :span="8" v-for="item in quickLink" :key="item.id" class="quick-col" @click="clickLink(item)">
                  <div class="quick-item">
                    <img :src="item.url" alt="" />
                  </div>
                  <div class="quick-type ">{{ item.title }}</div>
                </a-col>
              </a-row>
            </div>
            <div class="used">
              <div class="uesd-titile">Recently Visited</div>
              <a-row :gutter="[24, 24]">
                <a-col :span="8" v-for="item in rencentArr" :key="item.id" class="quick-col" @click="clickLink(item)">
                  <div class="quick-item">
                    <img :src="item.url" alt="" />
                  </div>
                  <div class="quick-type ">{{ item.title }}</div>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="shipment-title" @click="$router.push('/shipment')">
      Last 20 Shipments
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
        style="padding-left:4px;">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.35352 10.2317L3.64641 9.52458L7.18194 5.98905L3.64641 2.45352L4.35351 1.74641L8.24257 5.63546C8.24259 5.63548 8.2426 5.6355 7.88905 5.98905L8.24257 5.63546L8.59616 5.98905L4.35352 10.2317Z"
          fill="#4E5969" />
      </svg>
    </div>
    <div class="table-cont" :style="changeform.operate_id.length > 0 ? 'padding-bottom:96px;' : 'padding-bottom:48px;'">
      <a-spin :loading="loading" tip="Loading...">
        <a-table :columns="listColumns" :data="listData.value" :bordered="false" :pagination="false"
          :scroll="{ x: tableWidth, y: 800 }" :row-selection="rowSelection" @select-all="changmsg" @select="selectVal"
          :selected-keys="changeform.operate_id" @cell-click="rowClick">
          <template #empty>
            <div v-if="listData.value.length === 0 && loading === false" :style="{ height: 500 + 'px' }"
              class="empty-cont">
              <img src="../../assets/images/empty/listempty.svg" alt="">
              <div>No results found</div>
            </div>
            <div v-if="loading === true" style="height:300px;"></div>
          </template>
          <template #category="{ record }">
            <a-tag color="orange" v-if="record.category === 'Awaiting Shipment'">{{
              record.category
            }}</a-tag>
            <a-tag color="blue" v-if="record.category === 'In Transit'">{{
              record.category
            }}</a-tag>
            <a-tag color="red" v-if="record.category === 'Exceptional'">{{
              record.category
            }}</a-tag>
            <a-tag color="green" v-if="record.category === 'Delivered'">{{
              record.category
            }}</a-tag>
            <a-tag color="cyan" v-if="record.category === 'Dispatching'">{{
              record.category
            }}</a-tag>
            <a-tag v-if="record.category === 'Canceled'">{{
              record.category
            }}</a-tag>
            <a-tag color="purple" v-if="record.category === 'Callback'">{{
              record.category
            }}</a-tag>
            <a-tag color="purple" v-if="record.category === 'Change Address'">{{
              record.category
            }}</a-tag>
          </template>
          <template #tracking="{ record }">
            <div v-if="record.tracking_id">
              <span>{{
                record.tracking_id
              }}
              </span>
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.tracking_id)" />
              </a-tooltip>
            </div>
            <div v-else>N/A</div>
          </template>
          <template #ponumber="{ record }">
            <span style="color:#1d2129;cursor: pointer;"
              @click.stop="$router.push('/order/list/listdetail?orderId=' + record.orderId)">{{
                record.ponumber
              }}</span>
            <a-tooltip content="Copy">
              <icon-copy class="margin-l-10" @click.stop="copyVal(record.ponumber)" />
            </a-tooltip>
          </template>
          <template #orderid="{ record }">
            <span class="order-id">{{ record.order_unique_id }}</span>
            <a-tooltip content="Copy">
              <icon-copy class="margin-l-10" @click.stop="copyVal(record.order_unique_id)" />
            </a-tooltip>
          </template>
          <template #estcost="{ record }">
            <a-tooltip position="top">
              <a-tag>${{ toThousands(record.estcost) }}</a-tag>
              <template #content>
                <div v-for="val, k, i in record.quote_detail" :key="i">
                  <div>
                    <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                    <span v-else>-{{ k }}:</span>
                    <span> {{ val }}</span>
                  </div>
                </div>
              </template>
            </a-tooltip>
          </template>
          <template #sku="{ record }">
            <span v-if="record.sku">{{ record.sku }}</span>
            <span v-else>N/A</span>
          </template>
          <template #declaredvalue="{ record }">
            <span>${{ record.declaredvalue }}</span>
          </template>
          <template #action="{ record }">
            <a-tooltip content="Label">
              <a-button shape="circle" @click.stop="getLabel(record.label_file_name)" v-if="record.label_file_name"
                class="m-r-8">
                <icon-eye />
              </a-button>
            </a-tooltip>
            <a-tooltip content="Detail">
              <a-button shape="circle" @click="lookDetail(record.tracking_id)" v-if="record.tracking_id" class="m-r-8">
                <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style="padding-left:4px;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.35352 10.2317L3.64641 9.52458L7.18194 5.98905L3.64641 2.45352L4.35351 1.74641L8.24257 5.63546C8.24259 5.63548 8.2426 5.6355 7.88905 5.98905L8.24257 5.63546L8.59616 5.98905L4.35352 10.2317Z"
                    fill="#4E5969" />
                </svg>
              </a-button>
            </a-tooltip>
            <a-tooltip content="New Order">
              <a-button v-if="record.newOrderId > 0" shape="circle" type="primary"
                @click="newOrderDetail(record.newOrderId)">
                <icon-file />
              </a-button>
            </a-tooltip>
          </template>
        </a-table>
      </a-spin>
    </div>
  </div>
  <div class="shipment-operate" v-show="changeform.operate_id.length > 0">
    <a-space size="large">
      <a-checkbox v-model="checkedValue" @change="changmsg">
        {{ changeform.operate_id.length }} items
      </a-checkbox>
      <a-popconfirm content="Are you sure to call back?
                              The shipment(s) will be returned to the shipper." type="warning" ok-text="Call Back"
        cancel-text="Cancel" @ok="moreCallBack" style="width:276px;">
        <a-button status="warning" shape="round" v-if="!callDisable">Call Back</a-button>
      </a-popconfirm>
      <a-button status="warning" shape="round" v-if="!changeDisable" @click="moreChange">Change Address</a-button>
      <a-popconfirm content="Are you sure to cancel shipments you selected?" type="warning" ok-text="Cancel Shipment(s)"
        cancel-text="Back" @ok="moreCancel">
        <a-button status="warning" shape="round" v-if="!cancelDisable">Cancel</a-button>
      </a-popconfirm>
    </a-space>
  </div>
  <!-- 点击tracking的弹框 -->
  <div id="click-teacking">
    <a-drawer v-model:visible="trackingVisible" ok-text="Change Address" cancel-text="Return" title-align="start"
      :render-to-body="false" @cancel="trackingCancel" :width="465">
      <template #title>
        <a-space>
          <a-tag color="orange" v-if="trackData.trackVal.category === 'Awaiting Shipment'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="blue" v-if="trackData.trackVal.category === 'In Transit'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="red" v-if="trackData.trackVal.category === 'Exceptional'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="green" v-if="trackData.trackVal.category === 'Delivered'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="cyan" v-if="trackData.trackVal.category === 'Dispatching'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag v-if="trackData.trackVal.category === 'Canceled'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="purple" v-if="trackData.trackVal.category === 'Callback'">{{
            trackData.trackVal.category
          }}</a-tag>
          <a-tag color="purple" v-if="trackData.trackVal.category === 'Change Address'">{{
            trackData.trackVal.category
          }}</a-tag>
          <span>Tracking :{{ trackData.trackVal.tracking_id }}</span>
          <icon-copy @click="copyVal(trackData.trackVal.tracking_id)" />
        </a-space>
      </template>
      <!-- <div id="mapCenter" class="rolad-line"></div> -->
      <div class="proofImg" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.photograph">
        <div class="proof-til">Proof of receipt</div>
        <img :src="proofReceipt" alt="" />
        <div class="proof-footer">
          <a-space size="large">
            <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10" viewBox="0 0 12 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                  fill="white" />
              </svg></a>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
              style="padding-right:20px;" @click="downloadImg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
                fill="white" />
            </svg>
          </a-space>
        </div>
      </div>
      <a-card style="margin-top:20px;">
        <template #title>
          <!-- Order ID#{{ trackData.trackVal.order_unique_id }}
          <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" /> -->
          <div style="display:flex;justify-content:space-between;">
            <div>
              Order ID#{{ trackData.trackVal.order_unique_id }}
              <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" />
            </div>
            <a-button v-if="trackData.trackVal.can_add_note === 1" shape="round" size="mini" @click="openNote">+ Add
              Notes</a-button>
          </div>
        </template>
        <a-timeline>
          <a-timeline-item
            :label="trackData.trackVal.sender.city + ',' + trackData.trackVal.sender.state + ',' + trackData.trackVal.sender.country"
            lineType="dashed">
            Shipper:
          </a-timeline-item>
          <a-timeline-item lineType="dashed"
            :label="trackData.trackVal.recipient.city + ',' + trackData.trackVal.recipient.state + ',' + trackData.trackVal.recipient.country"
            dotColor="#C9CDD4">
            Recipient:
          </a-timeline-item>
        </a-timeline>
        <div class="notice" v-if="trackData.trackVal.notes.length > 0">
          <div class="til">Notes:</div>
          <div class="notice-cont" v-for="note, i in trackData.trackVal.notes" :key="i">
            <div class="who">From: {{ note.from_desc }}</div>
            <div class="cont">{{ note.content }}</div>
            <div class="time">{{ note.created_at }}</div>
          </div>
        </div>
      </a-card>
      <div class="logistics">
        <div class="road-steps">Details of Logistics</div>
        <div class="proof" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.autograph">
          Proof of receipt:
          <a-tooltip content="Download">
            <a-button shape="circle" style="margin-left:10px;" @click="downloadsign">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.5 5.57816V0.25H5.5V5.5787L6.91448 4.16422L7.62159 4.87132L5.00027 7.49264L2.37895 4.87132L3.08605 4.16422L4.5 5.57816ZM1.5 8.75V7.75H0.5V9.75H9.5V7.75H8.5V8.75H1.5Z"
                  fill="#4E5969" />
              </svg>
            </a-button>
          </a-tooltip>
          <!-- <a-popover :content-style="{ padding: 0 }">
            <template #title>
              <div class="proof-title">Proof of receipt</div>
            </template>
            <img :src="proofReceipt" alt="" />
            <template #content>
              <div class="content-img">
                <img :src="proofReceipt" alt="" />
                <div class="proof-footer">
                  <a-space size="large">
                    <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10"
                        viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                          fill="white" />
                      </svg></a>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style="padding-right:20px;" @click="downloadImg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
                        fill="white" />
                    </svg>
                  </a-space>
                </div>
              </div>
            </template>
          </a-popover> -->
        </div>
      </div>
      <div class="logs">
        <a-timeline>
          <a-timeline-item v-for="item in  trackData.trackVal.logs" :key="item" :label="item.date" dotColor="#ECECEC">
            {{ item.description }}<br />
            <a-typography-text type="secondary" :style="{ fontSize: '12px', marginTop: '4px' }">{{ item.location }}
            </a-typography-text>
          </a-timeline-item>
        </a-timeline>
      </div>
      <template #footer>
        <div class="operate-btn">
          <a-space>
            <a-popconfirm content="Are you sure to Call Back?
                              You can go to Call Back Order
                               to view the order(s)." type="warning" ok-text="Call Back" cancel-text="Cancel"
              @ok="trackCallBack(trackData.trackVal.shipment_id)" class="warnPop" style="width:276px;">
              <a-button v-if="trackData.trackVal.can_callback" status="warning" shape="round">
                Call Back</a-button>
            </a-popconfirm>
            <a-button v-if="trackData.trackVal.can_claim" shape="round" status="warning" @click="goClaim">Claim</a-button>
            <a-popconfirm content="Are you sure to cancel shipments you selected?
                               You can't undo this action." type="warning" ok-text="Cancel Shipment(s)"
              cancel-text="Back" @ok="trackCancel(trackData.trackVal.shipment_id)" class="warnPop" style="width:276px;">
              <a-button v-if="trackData.trackVal.can_cancel" shape="round" status="warning">Cancel</a-button>
            </a-popconfirm>
            <a-button v-if="trackData.trackVal.can_change_address" shape="round" status="warning"
              @click="trackChangeAddress(trackData.trackVal.shipment_id)" class="warnPop">Change Address
            </a-button>
            <a-button v-if="trackData.trackVal.revised_order_id > 0" type="primary"
              @click="newOrderDetail(trackData.trackVal.revised_order_id)" shape="round">New Order
            </a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>
  </div>
  <!-- 修改地址的弹框 -->
  <div id="change-address-frame">
    <a-modal v-model:visible="visible" ok-text="Save" cancel-text="Reset" title-align="start" :render-to-body="false"
      @cancel="handleCancel" :width="520">
      <template #title>
        <div class="change-title">
          <span class="til">Change Address</span>
        </div>
      </template>
      <a-form :model="addressform" layout="vertical">
        <a-form-item field="name" label="Address">
          <a-config-provider :locale="enUS">
            <a-select @change="toAddressVal" v-model="recipient">
              <a-option v-for="item, i in toAddress.option" :value="item.name" :key="i">{{ item.name }}
              </a-option>
            </a-select>
          </a-config-provider>
        </a-form-item>
      </a-form>
      <a-form :model="changeform" ref="formAddress" size="medium" layout="vertical">
        <a-row :gutter="[20, 20]">
          <a-col :span="12">
            <a-form-item field="to_name" label="Name" :rules="[
              { required: true, message: 'Name is required' },
              { maxLength: 35, minLength: 2, message: '2-35 characters' }
            ]">
              <a-input v-model="changeform.to_name" placeholder="Required 2-35 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item field="to_company" label="Company Name"
              :rules="[{ maxLength: 35, minLength: 2, message: '2-35 characters' }]">
              <a-input v-model="changeform.to_company" placeholder="Required 2-35 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item field="to_street1" label="Address Line1" :rules="[
              { required: true, message: 'Address line1 is required' },
              { maxLength: 35, minLength: 4, message: '4-35 characters' }
            ]">
              <a-input v-model="changeform.to_street1" placeholder="Required 4-35 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item field="to_street2" label="Address Line2"
              :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters' }]">
              <a-input v-model="changeform.to_street2" placeholder="Optional 1-35 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item field="to_zip" label="Zip Code" :rules="[
              {
                required: true,
                type: 'number',
                message: 'zip code is required'
              },
              { minLength: 5, maxLength: 10, message: '5-10 characters' }
            ]">
              <a-config-provider :locale="enUS">
                <a-select allow-search placeholder="Required 5-10 characters" v-model="changeform.to_zip"
                  @change="fromChange" @search="fromZipSearch" allow-clear :loading="fromLoading" :options="fromOptions"
                  autocomplete="off">
                  <template #empty>
                    <a-empty v-if="(fromIsempty === true)" />
                    <div class="empty" v-if="(fromZiperror === true)">
                      <a-space>
                        <icon-info-circle />
                        Zip error
                      </a-space>
                    </div>
                    <div class="noarea" v-if="(fromNoarea === true)">
                      <icon-empty />
                      Not in the delivery service area
                    </div>
                  </template>
                </a-select>
              </a-config-provider>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item field="to_city" label="City" :rules="[
              { required: true, message: 'City is required' },
              { maxLength: 35, minLength: 2, message: '2-35 characters' }
            ]">
              <a-input v-model="changeform.to_city" placeholder="Required 2-35 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item field="to_state" label="State" :rules="[{ required: true, message: 'state is required' }]">
              <a-config-provider :locale="enUS">
                <a-select v-model="changeform.to_state" placeholder="Please select" allow-search>
                  <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }}-{{ item.name }}
                  </a-option>
                </a-select>
              </a-config-provider>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item field="to_country" label="Country" :rules="[{ required: true, message: 'Country is required' }]">
              <a-select v-model="changeform.to_country" placeholder="Please select" disabled>
                <a-option value="US">United State</a-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item field="to_phone" label="Phone"
              :rules="[{ minLength: 10, maxLength: 15, message: '10-15 characters' }]">
              <a-input v-model="changeform.to_phone" placeholder="Optional 10-15 characters" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item field="to_extension" label="Extension"
              :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
              <a-input v-model="changeform.to_extension" placeholder="Optional" />
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item field="to_email" label="Email" :rules="[
              {
                match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                message: 'email validation failed'
              }
            ]">
              <a-input v-model="changeform.to_email" placeholder="Optional" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <div class="change-footer">
          <a-space>
            <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
              @ok="resetForm" class="warnPop">
              <a-button shape="round">Reset</a-button>
            </a-popconfirm>
            <a-button type="primary" shape="round" html-type="submit" @click="submitChange">Change Address
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
  </div>
  <!-- 添加备注的弹框 -->
  <div id="notePop">
    <a-modal v-model:visible="noteVisible" ok-text="Save" cancel-text="Reset" :render-to-body="false" @cancel="cancelNote"
      :width="520" title="Add Notes">
      <a-form :model="noteForm" ref="noteRef" size="medium" layout="vertical">
        <a-form-item field="visible" label="Visible to whom">
          <a-checkbox-group v-model="noteForm.visible">
            <a-checkbox v-for="who, k in whoVisible" :value="k" :key="who">{{ who }}</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item field="content" label="Content" :rules="[
          { required: true, message: 'Please enter' }
        ]" :validate-trigger="['blur']">
          <a-textarea v-model="noteForm.content" placeholder="Please enter" allow-clear />
        </a-form-item>
      </a-form>
      <template #footer>
        <div class="change-footer">
          <a-space>
            <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
              @ok="resetNote" class="warnPop">
              <a-button shape="round">Reset</a-button>
            </a-popconfirm>
            <a-button type="primary" shape="round" html-type="submit" @click="submitNote">Submit
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { workbench } from '@/api/homepage.js'
import { shipmentCallback, addressList, changeAddress, orderDetail, shipmentCancel, getZipCode, searchLoginTracking, shipmentAddNote } from '@/api/order.js'
import { toThousands } from '@/utils/handleNumber.js'
import { WorkbenchShipmentOverview } from '@/api/usercenter.js'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { shipmentList } from '@/api/shipment.js'
import { Message, Notification, Button } from '@arco-design/web-vue'
import { IconCopy, IconEye, IconInfoCircle, IconEmpty, IconFile } from '@arco-design/web-vue/es/icon'
// 使用loading
import { load } from '@/utils/loading.js'
import local from '@/utils/local.js'
const state = JSON.parse(local.get('configInfo')).state
const Username = JSON.parse(local.get('userInfo'))
const loading = ref(false)
const router = useRouter()
// Wallet-----------------------------------
let balance = ref(0)
let expenseval = ref(0)
let rechargeval = ref(0)
let expense = reactive({ val: {} })
let recharge = reactive({ val: {} })
async function getData () {
  const msg = await workbench({})
  if (msg.code === 0) {
    balance.value = toThousands(msg.data.balance)
    expenseval.value = toThousands(msg.data.expense.this_month)
    rechargeval.value = toThousands(msg.data.recharge.this_month)
    expense.val = msg.data.expense
    recharge.val = msg.data.recharge
  }
}
getData()
// Shipments Overview----------------------------
const selsectOverview = ref(1)
const Overview = (value) => {
  selsectOverview.value = value
  shipmentOver()
}
const shipmentView = ref({})
const shipStatus = ref(false)
const shipmentOver = async () => {
  shipStatus.value = false
  const msg = await WorkbenchShipmentOverview({
    type: selsectOverview.value
  })
  if (msg.code === 0) {
    shipStatus.value = true
    shipmentView.value = msg.data
  }
}
shipmentOver()
// table---------------------------------------------
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const listColumns = [
  {
    title: 'Tracking ID',
    dataIndex: 'tracking_id',
    slotName: 'tracking',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 220
  },
  {
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    title: 'Status',
    dataIndex: 'category',
    slotName: 'category',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Sku',
    dataIndex: 'sku',
    slotName: 'sku',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Dimension(In)',
    dataIndex: 'dimension',
    slotName: 'dimension',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Weight(Lb)',
    dataIndex: 'weight',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Declared Value',
    dataIndex: 'declaredvalue',
    slotName: 'declaredvalue',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Est.Cost',
    dataIndex: 'estcost',
    slotName: 'estcost',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.estcost) > parseFloat(b.estcost)) {
          sortVal = 1;
        } else if (parseFloat(a.estcost) < parseFloat(b.estcost)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    width: 100
  },
  {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    slotName: 'action',
    width: 150
  }
]
let listData = reactive({ value: [] })
const getShipmentList = async () => {
  loading.value = true
  const msg = await shipmentList({
    category: ['awaitShip', 'inTransit', 'exceptional', 'delivered', 'dispatching', 'canceled', 'callback', 'changeAddress'],
    page: 1
  })
  if (msg.code === 0) {
    loading.value = false
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].id, created_at: info[i].created_at, category: info[i].category, tracking_id: info[i].tracking_id, ponumber: info[i].ponumber, service_type: info[i].service_type, estcost: info[i].estimate_shipping_charge, clallback: info[i].can_callback, cancel: info[i].can_cancel, changeaddress: info[i].can_change_address, claim: info[i].can_claim, label_file_name: info[i].label_file_name, orderId: info[i].order_id, sku: info[i].sku, dimension: info[i].dimension, weight: info[i].weight, declaredvalue: info[i].insurance, reference1: info[i].reference1, reference2: info[i].reference2, reference3: info[i].reference3, invoicenumber: info[i].invoice_number, order_unique_id: info[i].order_unique_id, actcost: info[i].actual_shipping_charge, adjust: info[i].adjust_amount, quote_detail: info[i].quote_detail, refund: info[i].refundAmount, recipientname: info[i].recipient_name, newOrderId: info[i].revised_order_id })
    }
    listData.value = currentData
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 表格渲染
getShipmentList()
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
// 获取label
const getLabel = (label) => {
  window.open(label, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const tableWidth = ref(0)
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
  }
})
const changeform = reactive({
  to_name: '',
  to_address: '',
  to_company: '',
  to_phone: '',
  to_street1: '',
  to_street2: '',
  to_city: '',
  to_state: '',
  to_country: 'US',
  to_zip: '',
  to_email: '',
  to_extension: '',
  operate_id: []
})
const checkedValue = ref(false)
const changeDisable = ref(true)
const callDisable = ref(true)
const cancelDisable = ref(true)
// 多选
const changmsg = (checked) => {
  checkedValue.value = checked
  if (checked) {
    changeform.operate_id = []
    // 选中的所有key
    listData.value.forEach((item) => {
      changeform.operate_id.push(item.key)
    })
    // 判断是否可以做批量操作
    if (listData.value.every(item => { return item.changeaddress === true })) {
      changeDisable.value = false
    }
    if (listData.value.every(item => { return item.clallback === true })) {
      callDisable.value = false
    }
    if (listData.value.every(item => { return item.cancel === true })) {
      cancelDisable.value = false
    }
  } else {
    changeform.operate_id = []
    changeDisable.value = true
    callDisable.value = true
    cancelDisable.value = true
  }
}
// 单选
function selectVal (rowKeys) {
  changeform.operate_id = rowKeys
  // 循环listData.value，选出选中的数组
  if (rowKeys.length === listData.value.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  let newArr = []
  for (let i = 0; i < rowKeys.length; i++) {
    for (let j = 0; j < listData.value.length; j++) {
      if (rowKeys[i] === listData.value[j].key) {
        newArr.push(listData.value[j])
      }
    }
  }
  if (newArr.length > 0) {
    // 判断是否可以进行按钮操作
    if (newArr.every(item => { return item.changeaddress === true })) {
      changeDisable.value = false
    } else {
      changeDisable.value = true
    }
    if (newArr.every(item => { return item.clallback === true })) {
      callDisable.value = false
    } else {
      callDisable.value = true
    }
    if (newArr.every(item => { return item.cancel === true })) {
      cancelDisable.value = false
    } else {
      cancelDisable.value = true
    }
  } else {
    changeDisable.value = true
    callDisable.value = true
    cancelDisable.value = true
  }
}
// tracking 详情
const trackingVisible = ref(false)
const drawerTracking = ref('')
let trackData = reactive({
  trackVal: {
    sender: {},
    recipient: {},
    notes: []
  }
})

// const script = ref('')
// onMounted(() => {
//   script.value = document.createElement('script')
// })
const lookDetail = (id) => {
  drawerTracking.value = id
  rowDetail()
}
const rowClick = async (record) => {
  if (!record.tracking_id) {
    return
  }
  clickTrackingOrderId.value = record.orderId
  drawerTracking.value = record.tracking_id
  rowDetail()
}
const clickTrackingOrderId = ref(null)
// 起点
// let startAddress = ref('')
// 终点
// let endAddress = ref('')
// 签收pingzheng
let proofReceipt = ref('')
const rowDetail = async () => {
  const msg = await searchLoginTracking({
    trackingId: drawerTracking.value
  })
  if (msg.code === 0) {
    if (!msg.data.photograph) {
      proofReceipt.value = msg.data.pod_file
    } else {
      proofReceipt.value = msg.data.photograph
    }
    trackData.trackVal = msg.data
    // let lastLocation = msg.data.lastLocation
    // startAddress.value = lastLocation.street1 + ',' + lastLocation.street2 + ',' + lastLocation.city + ',' + lastLocation.state + ',' + lastLocation.zip + ',' + lastLocation.country
    // let endLocation = msg.data.recipient
    // endAddress.value = endLocation.street1 + ',' + endLocation.street2 + ',' + endLocation.city + ',' + endLocation.state + ',' + endLocation.zip + ',' + endLocation.country
    // script.value.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCYZLGxJ3yCFkGJmmT1gOOAh-_7KP1H5f8&callback=initMapCenter&libraries=&v=weekly'
    // script.value.setAttribute('defer', 'defer')
    // document.body.appendChild(script.value)
    nextTick(function () {
      trackingVisible.value = true
      // if (directionsService !== undefined) {
      //   calculateAndDisplayRoute(directionsService, directionsRenderer)
      // }
    })
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// let directionsService
// let directionsRenderer
// window.initMapCenter = function () {
//   directionsService = new google.maps.DirectionsService();
//   directionsRenderer = new google.maps.DirectionsRenderer();
//   const map = new google.maps.Map(document.getElementById("mapCenter"), {
//     zoom: 10
//   })
//   directionsRenderer.setMap(map);
//   // 刚进入页面时渲染函数
//   calculateAndDisplayRoute(directionsService, directionsRenderer)
// }
// function calculateAndDisplayRoute (directionsService, directionsRenderer) {
//   directionsService.route({
//     origin: startAddress.value,
//     destination: endAddress.value,
//     optimizeWaypoints: true,
//     travelMode: google.maps.TravelMode.DRIVING
//   }, (response, status) => {
//     if (status === "OK") {
//       directionsRenderer.setDirections(response)
//     }
//   }
//   )
// }
// 关闭TRACKING弹框
const trackingCancel = () => {
  trackingVisible.value = false
}
// 弹框 call back
const trackCallBack = async (shipmentId) => {
  changeform.operate_id.push(shipmentId)
  trackingVisible.value = false
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: changeform.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    // Notification.success('Called Back.')
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    changeform.operate_id = []
    // currentpage.value = 1
    getShipmentList()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payWorkcenter1',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification1}>
        Recharge
      </Button>
    })
    changeform.operate_id = []
    listData.value = []
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification1 = () => {
  Notification.remove('payWorkcenter1')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// tracking cancel
const trackCancel = async (shipmentId) => {
  trackingVisible.value = false
  load.show('Canceling the shipment(s)...')
  const msg = await shipmentCancel({
    id: shipmentId
  })
  console.log(msg)
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment(s) has/have been canceled.'
    })
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const tracking = ref('')
// 点击弹框 change address
const trackChangeAddress = async (val, track) => {
  visible.value = true
  changeform.operate_id.push(val)
  tracking.value = track
  const msg = await orderDetail({
    order_id: clickTrackingOrderId.value
  })
  if (msg.code === 0) {
    changeform.to_name = msg.data.to_name
    changeform.to_company = msg.data.to_company
    changeform.to_street1 = msg.data.to_street1
    changeform.to_street2 = msg.data.to_street2
    changeform.to_city = msg.data.to_city
    changeform.to_state = msg.data.to_state
    changeform.to_zip = msg.data.to_zip
    changeform.to_phone = msg.data.to_phone
    changeform.to_extension = msg.data.to_extension
    changeform.to_email = msg.data.to_email
  }
}
// call back和change address的订单详情
const newOrderDetail = (trackingid) => {
  let routerUrl = router.resolve({ path: '/order/list/listdetail', query: { orderId: trackingid } })
  window.open(routerUrl.href, '_blank')
}

// change address
const visible = ref(false)
const addressform = reactive({
  address: ''
})
const moreChange = () => {
  visible.value = true
}
const toAddress = reactive({
  option: []
})
const getToAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 2,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    toAddress.option = msg.data.data
  }
}
getToAddress()
const recipient = ref('')
const toAddressVal = (val) => {
  recipient.value = val
  toAddress.option.forEach((item) => {
    if (item.name === val) {
      changeform.to_name = item.name
      changeform.to_company = item.company
      changeform.to_street1 = item.street1
      changeform.to_street2 = item.street2
      changeform.to_city = item.city
      changeform.to_state = item.state
      changeform.to_zip = item.zip
      changeform.to_phone = item.phone
      changeform.to_extension = item.extension
      changeform.to_email = item.email
    }
  })
}
const formAddress = ref(null)
// 关闭修改地址弹框
const handleCancel = () => {
  recipient.value = ''
  formAddress.value.resetFields()
  visible.value = false
}
// 重置修改表单、
const resetForm = () => {
  recipient.value = ''
  formAddress.value.resetFields()
}
// 提交修改
const submitChange = () => {
  formAddress.value.validate(async (record) => {
    if (!record) {
      visible.value = false
      trackingVisible.value = false
      load.show('Changing Address...')
      const msg = await changeAddress({
        to_name: changeform.to_name,
        to_company: changeform.to_company,
        to_street1: changeform.to_street1,
        to_street2: changeform.to_street2,
        to_city: changeform.to_city,
        to_state: changeform.to_state,
        to_zip: changeform.to_zip,
        is_residential: null,
        to_country: changeform.to_country,
        to_phone: changeform.to_phone,
        to_extension: changeform.to_extension,
        to_email: changeform.to_email,
        shipment_ids: changeform.operate_id
      })
      console.log(msg)
      if (msg.code === 0) {
        load.hide()
        Notification.success({
          title: 'Success',
          content: 'Changed Address.'
        })
        // 清空id
        changeform.operate_id = []
        listData.value = []
        getShipmentList()
      } else if (msg.code === 11) {
        load.hide()
        // 余额不足
        Notification.warning({
          id: 'payWorkcenter2',
          title: 'Insufficient Balance!',
          content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
          duration: 0,
          closable: true,
          footer: <Button type="primary" size="small" shape="round" onClick={closeNotification2}>
            Recharge
          </Button>
        })
        changeform.operate_id = []
        listData.value = []
        getShipmentList()
      } else {
        load.hide()
        Notification.error({
          title: 'Error',
          content: msg.message
        })
      }
    }
  })
}
const closeNotification2 = () => {
  Notification.remove('payWorkcenter2')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量call back
const moreCallBack = async () => {
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: changeform.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    changeform.operate_id = []
    getShipmentList()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payWorkcenter3',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification3}>
        Recharge
      </Button>
    })
    changeform.operate_id = []
    listData.value = []
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification3 = () => {
  Notification.remove('payWorkcenter3')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量cancel
const moreCancel = async () => {
  load.show('Canceling the shipment...')
  const msg = await shipmentBatchCancel({
    ids: changeform.operate_id
  })
  console.log(msg)
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment has been canceled.'
    })
    changeform.operate_id = []
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const quickLink = [
  { id: 1, title: 'Add Order', url: require('../../assets/images/workbench/addnew.svg'), link: '/order/add/addneworder' },
  { id: 2, title: 'Shipments', url: require('../../assets/images/workbench/shipment.svg'), link: '/shipment' },
  { id: 3, title: 'Wallet', url: require('../../assets/images/workbench/mobile.svg'), link: '/usercenter/wallet' },
  { id: 4, title: 'My Profile', url: require('../../assets/images/workbench/user.svg'), link: '/usercenter/myprofile' },
  { id: 5, title: 'Transactions', url: require('../../assets/images/workbench/sync.svg'), link: '/usercenter/transaction' },
  { id: 6, title: 'Claim', url: require('../../assets/images/workbench/claim.svg'), link: '/usercenter/claimlist' }
]
const rencentArr = ref([])
const recently = JSON.parse(local.get('recent'))
function unique (arr) {
  const res = new Map()
  return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1) && !res.has(a.title) && res.set(a.title, 1))
}
if (!recently) {
  rencentArr.value = []
} else {
  // console.log(recently)
  rencentArr.value = unique(recently)
}
const clickLink = (itm) => {
  router.push(itm.link)
  rencentArr.value.unshift(itm)
  rencentArr.value = unique(rencentArr.value)
  if (rencentArr.value.length > 3) {
    rencentArr.value.pop()
  }
  local.set('recent', JSON.stringify(rencentArr.value))
}
const downloadsign = () => {
  window.open(proofReceipt.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const downloadImg = () => {
  let image = new Image()
  image.setAttribute("crossOrigin", "anonymous")
  image.onload = function () {
    let canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext("2d")
    context.drawImage(image, 0, 0, image.width, image.height)
    let url = canvas.toDataURL("image/png")
    let a = document.createElement("a")
    let event = new MouseEvent("click")
    a.download = "POD_" + trackData.trackVal.tracking_id
    a.href = url
    a.dispatchEvent(event)
  }
  image.src = proofReceipt.value + '?' + Math.random
}
const numReg = /^[0-9]*$/
const fromOptions = ref([])
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromLoading = ref(false)
const fromaddress = ref(false)
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromLoading.value = false
        fromNoarea.value = false
        fromOptions.value = []
        fromaddress.value = msg.data
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromLoading.value = false
        fromOptions.value = []
        fromaddress.value = []
        fromNoarea.value = true
      }
    }
  }
}
const fromChange = (value) => {
  // console.log(fromOptions.value)
  if (fromaddress.value !== []) {
    fromaddress.value.forEach((item) => {
      if (item.value === value) {
        changeform.to_city = item.city
        changeform.to_state = item.state
        changeform.to_zip = item.zip
      }
    })
  }
}
// 投诉
const goClaim = () => {
  router.push('/usercenter/claim')
}
// 添加备注
const whoVisible = JSON.parse(local.get('configInfo')).shipment_note_visible
const noteVisible = ref(false)
const noteRef = ref(null)
const noteForm = reactive({
  visible: [],
  content: ''
})
const openNote = () => {
  noteVisible.value = true
}
const cancelNote = () => {
  noteVisible.value = false
  noteRef.value.resetFields()
}
const resetNote = () => {
  noteRef.value.resetFields()
}
const submitNote = () => {
  noteRef.value.validate(async (record) => {
    if (!record) {
      const msg = await shipmentAddNote({
        visible: noteForm.visible,
        shipment_id: trackData.trackVal.shipment_id,
        content: noteForm.content
      })
      console.log(msg)
      if (msg.code === 0) {
        Notification.success('Submitted.')
        noteVisible.value = false
        noteRef.value.resetFields()
        rowDetail()
      } else {
        Notification.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.m-r-8 {
  margin-right: 8px;
}

.content-box {
  padding: 26px 24px 0 24px;
  min-width: 1330px;

  .welcom {
    color: var(--color-text-1);
    font-weight: 500;
    font-size: 20px;
  }
}

.more-msg .arco-btn {
  color: var(--color-text-2);
}

.price {
  color: var(--color-text-1);
  font-weight: 600;
  font-size: 24px;
}

.quick-type {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
}

.gocenter {
  text-align: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-satrt {
  display: flex;
  justify-content: flex-start;
}

.quick-box {
  border-bottom: 1px solid var(--color-border-2);
  padding-bottom: 28px;
}

.quick-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    .quick-item {
      color: rgb(var(--primary-6));
      background-color: rgb(var(--primary-1));
      overflow: hidden;

      img {
        // position: relative;
        // left: -9999999px;
        // filter: drop-shadow(rgb(var(--primary-6)) 9999999px 0);
        filter: drop-shadow(rgb(var(--primary-6)) 0px 500px 0px);
        transform: translateY(-500px);
      }
    }

    .quick-type {
      color: rgb(var(--primary-6));
    }
  }
}

.quick-item {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  color: rgb(var(--dark-gray-1));
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  background-color: rgb(var(--gray-1));
  border-radius: 4px;
}

.used {
  padding-top: 28px;

  .uesd-titile {
    padding-bottom: 16px;
    color: var(--color-text-3);
    font-weight: 400;
    font-size: 14px;
  }
}

.shipment-title {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 16px;
  margin: 24px 0;
  cursor: pointer;
  width: 170px;
}

.margin-l-10 {
  margin-left: 10px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  color: #C9CDD4;
}

:deep(.arco-spin) {
  display: block;
}

.shipment-operate {
  width: 100%;
  background-color: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--color-border-2);
  z-index: 99;
}

.custom-checkbox-card-mask-dot {
  width: 8px;
  height: 2px;
  border-radius: 2px;
}

.custom-checkbox-card-title {
  color: var(--color-text-1);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
}

.custom-checkbox-card:hover,
.custom-checkbox-card-checked,
.custom-checkbox-card:hover .custom-checkbox-card-mask,
.custom-checkbox-card-checked .custom-checkbox-card-mask {
  border-color: rgb(var(--primary-6));

}

.custom-checkbox-card-checked .custom-checkbox-card-mask {
  background-color: rgb(var(--primary-6));
}

.custom-checkbox-card-checked .custom-checkbox-card-mask-dot {
  background-color: #fff;
}

.custom-checkbox-card-mask {
  height: 14px;
  width: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid var(--color-border-2);
  box-sizing: border-box;
}

.order-id {
  color: rgb(var(--primary-6));
}

// #mapCenter {
//   height: 255px;
// }
.proofImg {
  position: relative;

  .proof-til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
    padding-bottom: 12px;
  }

  img {
    height: 255px;
    width: 100%;
    border-radius: 12px;
  }

  .proof-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 6px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    text-align: right;
    line-height: 50px;
    margin-right: 20px;
    z-index: 2;
    border-radius: 0 0 12px 12px;

    svg {
      cursor: pointer;
    }
  }
}

:deep(.logs .arco-timeline .arco-timeline-item:first-child .arco-timeline-item-content) {
  font-weight: bold;

  .arco-typography {
    font-weight: normal;
  }
}

// 备注
.notice {

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .notice-cont {
    padding-top: 16px;

    .who {
      margin-bottom: 4px;
      color: var(--color-text-1);
      font-size: 14px;
      line-height: 1.5715;
    }

    .cont {
      font-size: 12px;
      margin-top: 4px;
      color: var(--color-text-2);
      line-height: 1.5715;
    }

    .time {
      color: var(--color-text-3);
      font-size: 12px;
      line-height: 1.667;
    }
  }
}

// 日志
.logistics {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;

  .road-steps {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .proof {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-text-3);

    img {
      width: 32px;
      height: 32px;
      padding-left: 8px;
    }
  }
}

.proof-title {
  padding: 11px 16px;
}

.content-img {
  position: relative;

  img {
    width: 360px;
    height: 204px;
  }

  // .proof-footer {
  //   position: absolute;
  //   width: 360px;
  //   height: 50px;
  //   left: 0;
  //   bottom: 6px;
  //   background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  //   text-align: right;
  //   line-height: 50px;
  //   margin-right: 20px;
  //   z-index: 2;

  //   svg {
  //     cursor: pointer;
  //   }
  // }
}

.change-title {
  text-align: center;
  width: 100%;

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--Text-5);
  }

  .track {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
    padding-left: 8px;
  }
}

.change-footer {
  text-align: center;
  width: 100%;
}

.status {
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  padding: 1px 8px;
}

//  点击修改地址的弹框
#change-address-frame {
  :deep(.arco-modal-header) {
    height: 80px;

    .change-frame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .titlt-left {
        .big-title {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          margin-right: 20px;
        }

        .small-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: rgba(109, 122, 155, 0.7);
        }
      }

      .select-address {
        margin-right: 30px;
        margin-top: 42px;
        width: 275px;

        .arco-form-item-label-col>.arco-form-item-label {
          height: 52px;
          line-height: 35px;
          color: #000;
          padding-right: 8px;
        }
      }
    }
  }
}

.cost {
  padding: 1px 8px;
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.adjust {
  padding: 1px 8px;
  color: rgb(var(--success-6));
  background-color: rgb(var(--success-1));
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.empty-cont {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
